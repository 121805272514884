/* purgecss start ignore */
@tailwind base;
@tailwind components;

html {
  @apply bg-white text-black;
}

html,
body {
  min-height: 100%;
  min-width: 100%;
}

@font-face {
  font-family: IndustryInc-Base;
  src: url('/fonts/3A2884_0_0.woff2') format('woff2');
  src: url('/fonts/3A2884_0_0.woff') format('woff');
  font-display: swap;
}

h1,
h2,
h3,
h4 {
  @apply mb-4;
}

h1 {
  @apply text-5xl;
}

h2 {
  @apply text-4xl;
}

h3 {
  @apply text-3xl;
}
h4 {
  @apply text-2xl;
}
.surface {
  @apply bg-black-900;
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.rotate-change-color {
  animation: rotation-with-color-change 2s linear infinite;
}

@keyframes rotation-with-color-change {
  0% {
    transform: rotate3d(0);
    color: #000;
  }
  50% {
    transform: rotate3d(0, 1, 0, 180deg);
    color: #e9501f;
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
    color: #000;
  }
}

/* Prevent yellow coloring on autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

/* Color asterisks on required fields */
.MuiInputLabel-asterisk {
  color: red !important;
}

/* purgecss end ignore */

@tailwind utilities;
